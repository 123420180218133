import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import moment from 'moment'
import TodayCreateOrder from '../components/dashboard/TodayCreateOrder'
import TodayDispatchedOrder from '../components/dashboard/TodayDispatchedOrder'
import TodayCancleOrder from '../components/dashboard/TodayCancleOrder'
import TodayReturnProduc from '../components/dashboard/TodayReturnProduc'
import BarChat from '../components/dashboard/BarChat'
import PolarChart from '../components/dashboard/PolarChart'

const Dashboard = () => {
    const navigate = useNavigate();
    const misid = localStorage.getItem('misdata');
    const [mis, setMis] = useState([]);

    useEffect(() => {
        if (misid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(misid);
        }
        else {
            navigate("/");
        }
    }, [misid]);

    const getDashboardSingleEmpData = (misid) => {
        getSingleDataFromDatabase(`mis-get-single-data/${misid}`).then((res) => {
            console.log("responsedata", res);
            if (res.data.status === 1) {
                setMis(...[res.data.misdata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    if (moment().format('a') === "pm") {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{mis?.mis_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{mis?.mis_name}</span></h4>
                        )
                    }
                })()}

                {/* Leave Balance */}
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-3">
                        <TodayCreateOrder />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <TodayDispatchedOrder />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <TodayCancleOrder />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3">
                        <TodayReturnProduc />
                    </div>
                </div>
                {/* End */}
                {/* New Joining Approvel birthday */}
                <div className="row chartsection">
                    <div className="col-12 col-lg-6 col-xl-8">
                        <BarChat />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4">
                        <PolarChart />
                    </div>
                </div>
                {/* End */}
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
