import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AllOrder from "./pages/AllOrder";
import CMSOrders from "./pages/CMSOrders";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-loading-skeleton/dist/skeleton.css'
import MacamoOrder from "./pages/MacamoOrder";
import SatkartarOrders from "./pages/SatkartarOrders";


function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<AllOrder />} />
          <Route path="/cms-orders" element={<CMSOrders />} />
          <Route path="/macamo-orders" element={<MacamoOrder />} />
          <Route path="/satkartar-orders" element={<SatkartarOrders />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
