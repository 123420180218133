import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { MDBDataTable} from 'mdbreact';
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import { FaRegEye } from "react-icons/fa";

const AllOrder = () => {
    const [cmsorder, setCmsorder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const misid = localStorage.getItem('misdata');
    const navigate = useNavigate();

    useEffect(() => {
        if (misid) {
            getAllCmsOrders()
        }
        else {
            navigate("/");
        }
    }, [misid]);
    

    const getAllCmsOrders = () => {
        setIsloading(true);
        fetchAllDataAPI('mis-get-cms-order-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setCmsorder(...[res.data.cmsorder]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }

    const data = {
        columns: [
            {
                label: 'Sr No.',
                field: 'srno',
                sort: 'asc',
            },
            {
                label: 'Order No.',
                field: 'order_number',
                sort: 'asc',
            },
            {
                label: 'Name',
                field: 'cust_name',
                sort: 'asc',
            },
            {
                label: 'Mobile',
                field: 'cust_phone',
                sort: 'asc',
            },
            {
                label: 'Total Price',
                field: 'subtotal_price',
                sort: 'asc',
            },
            {
                label: 'Order date',
                field: 'created_date',
                sort: 'asc',
            },
            {
                label: 'Order Time',
                field: 'created_time',
                sort: 'asc',
            },
            {
                label: 'State',
                field: 'state',
                sort: 'asc',
            },
            {
                label: 'City',
                field: 'city',
                sort: 'asc',
            },
            {
                label: 'Pincode',
                field: 'pincode',
                sort: 'asc',
            },
            {
                label: 'Payment Method',
                field: 'payment_method',
                sort: 'asc',
            },
            {
                label: 'Plateform Name',
                field: 'vendor_name',
                sort: 'asc',
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
            }
        ],
        rows: cmsorder
    };
    cmsorder.map((cmsorder,index)=>{
        cmsorder.srno = (
            `${index + 1}`
        );
    });
    cmsorder.map((order,)=>{
        order.action = (
            <Link to={`${order.order_number}`} className="badge badge-success">
                <FaRegEye style={{fontSize:"20px"}} />
            </Link>
        );
    });
    cmsorder.map((order)=>{
        order.vendor_name = (
            <span className="badge badge-warning">
                {order.vendor_name}
            </span>
        );
    });
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Orders</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="card-body">
                            <MDBDataTable
                                striped
                                bordered
                                small
                                data={data}
                                selectRows={true}
                                noBottomColumns={false}
                                className="text-center"
                            />
                        </div>
                    </div>
                </div>
            </ContentWrapper >
        </div >
    )
}

export default AllOrder