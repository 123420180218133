import React, {useState, useEffect } from 'react'
import favicon from "../assets/favicon.png"
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginAPI } from '../utils/api';
import loadingif from "../assets/loading-gif.gif";

const Home = () => {
    const [mobile, setMobile] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const misid = localStorage.getItem('empdata');
    useEffect(() => {
        if (misid) {
            navigate('/dashboard');
        }
        else{
            navigate("/");
        }
    }, [misid]);

    const NumericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) setMobile(e.target.value)
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (mobile.length === 10) {
            setLoading(true);
            loginAPI("mis-send-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("mismobile", mobile);
                    toast.success(res.data.msg);
                    setLoading(false);
                    navigate("/mobile-otp");
                }
                else {
                    setLoading(false);
                    toast.error(res.data.msg);
                }
            })
        }
        else {
            setLoading(false);
            toast.error("Invalid Mobile Number!!");
        }
    }

    return (
        <div className="homePage">
            <Helmet>
                <title>SATKARTAR:ADMIN::MIS LOGIN</title>
            </Helmet>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={favicon} className="brand_logo" alt="Logo" />
                            </div>
                            <h1 className="loginname">MIS LOGIN</h1>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <form method="post" onSubmit={handleLoginSubmit}>
                                <div className="mb-3 form-group">
                                    <label htmlFor="mis_mobile">Mobile Number</label>
                                    <input type="text" name="mis_mobile" className="form-control input_user" id="mis_mobile" placeholder="Enter the Mobile Number" required={true} onChange={NumericOnly} />
                                </div>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    {loading ? 
                                    <button disabled={true} type="submit" className="btn login_btn">
                                        <img src={loadingif} alt={loadingif} style={{width:"7%",marginTop:"-3px"}} /> SENDING...
                                    </button>
                                    :
                                    <button type="submit" className="btn login_btn">SEND MOBILE OTP</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
