import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom'
import { addFormData, fetchSatkartarOrderDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { CiExport } from 'react-icons/ci'
import { Table } from 'react-bootstrap'
import CsvDownloader from 'react-csv-downloader';
import Moment from 'react-moment'


const SatkartarOrders = () => {
    const [satorder, setSatOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [filtervalue, setFilterValue] = useState();
    const misid = localStorage.getItem('misdata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 100;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = satorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(satorder.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        if (misid) {
            getAllSatkaratrOrders()
        }
        else {
            navigate("/");
        }
    }, [misid]);

    const getAllSatkaratrOrders = () => {
        setIsloading(true);
        fetchSatkartarOrderDataAPI('get-all-order-for-crm').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setSatOrder(...[res.data.allorder]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const columns = [
        {
            id: 'orderid',
            displayName: 'Order No.'
        },
        {
            id: 'order_name',
            displayName: 'Name'
        },
        {
            id: 'order_mobile',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal',
            displayName: 'Total Price'
        },
        {
            id: 'productname',
            displayName: 'Product Name'
        },
        {
            id: 'order_address',
            displayName: 'Address'
        },
        {
            id: 'order_zipcode',
            displayName: 'Pincode'
        },
        {
            id: 'payment_type',
            displayName: 'Payment Method'
        },
        {
            id: 'order_status',
            displayName: 'Order Type'
        },
        {
            id: 'created_at',
            displayName: 'Order date'
        },
    ];
    const exportOrder = satorder;
    const handleFilter = async (e) => {
        e.preventDefault();
        if (filtervalue) {
            addFormData('mis-filter-order-data', filtervalue).then((res) => {
                if (res.data.status === 1) {
                    setSatOrder(res.data.filterdata);
                }
            })
        }
        else {
            getAllSatkaratrOrders()
        }
    };
    const resetOrder = () => {
        getAllSatkaratrOrders()
    }
    const handleInputs = e => {
        setFilterValue({
            ...filtervalue,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::SATKARTAR ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All CMS Orders</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xl-10">
                                        <form action="POST" onSubmit={handleFilter}>
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Search By Name</label>
                                                    <input name="cname" id="cname" className="form-control" type="text" placeholder="Search by Name" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="onumber">Search by Order Number</label>
                                                    <input name="onumber" id="onumber" className="form-control" type="text" placeholder="Search by Order Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cmobile">Search by Mobile Number</label>
                                                    <input name="cmobile" id="cmobile" className="form-control" type="text" placeholder="Search by Mobile Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-2">
                                                    <label htmlFor="odate">Search by Order Date</label>
                                                    <input name="odate" id="odate" className="form-control" type="date" placeholder="Search by Order Date" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-1">
                                                    <button type="submit" className="btn btn-success btn-md btnfilter">Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-12 col-lg-12 col-xl-2">
                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xl-6">
                                                <button onClick={resetOrder} className="btn btn-warning btn-md btnfilter">Reset</button>
                                            </div>
                                            <div className="col-12 col-lg-12 col-xl-6">
                                                <CsvDownloader filename="All Satkartar Orders"
                                                    extension=".csv"
                                                    datas={exportOrder}
                                                    columns={columns}
                                                >
                                                    <CiExport className="filtersvg" />
                                                    <button className="btn btn-info btn-md btnfilter"> Export</button>
                                                </CsvDownloader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Product Name</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Pincode</th>
                                        <th>Payment Method</th>
                                        <th>Order Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                        </>

                                        :
                                        records?.map((records, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td><span className="badge badge-success">{records?.orderid}</span></td>
                                                <td>{records?.order_name}</td>
                                                <td>{records?.order_mobile}</td>
                                                <td>{records?.productname}</td>
                                                <td>{records?.subtotal}</td>
                                                <td>
                                                    <Moment format="YYYY-MM-DD">
                                                        {records?.created_at}
                                                    </Moment>
                                                </td>
                                                <td>{records?.order_zipcode}</td>
                                                <td>{records?.payment_type}</td>
                                                {(() => {
                                                    if (records?.order_status === "Abandoned Cart") {
                                                        return (
                                                            <td><span className="badge badge-secondary">Abandoned Cart</span></td>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <td><span className="badge badge-info">Order</span></td>
                                                        )
                                                    }
                                                })()}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Product Name</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Pincode</th>
                                        <th>Payment Method</th>
                                        <th>Order Type</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default SatkartarOrders