import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { addFormData, fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';

const MacamoOrder = () => {
    const [cmsorder, setCmsorder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [filtervalue, setFilterValue] = useState();
    const misid = localStorage.getItem('misdata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 100;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = cmsorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(cmsorder.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    
    useEffect(() => {
        if (misid) {
            getAllCmsOrders()
        }
        else {
            navigate("/");
        }
    }, [misid]);

    const getAllCmsOrders = () => {
        setIsloading(true);
        fetchAllDataAPI('mis-get-cms-macamo-order-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setCmsorder(...[res.data.cmsorder]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const columns = [
        {
            id: 'order_number',
            displayName: 'Order No.'
        },
        {
            id: 'cust_name',
            displayName: 'Name'
        },
        {
            id: 'cust_phone',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal_price',
            displayName: 'Total Price'
        },
        {
            id: 'created_date',
            displayName: 'Order date'
        },
        {
            id: 'created_time',
            displayName: 'Order Time'
        },
        {
            id: 'state',
            displayName: 'State'
        },
        {
            id: 'city',
            displayName: 'City'
        },
        {
            id: 'pincode',
            displayName: 'Pincode'
        },
        {
            id: 'vendor_name',
            displayName: 'Plateform Name'
        },
        {
            id: 'shipingid',
            displayName: 'Shipping ID'
        },
        {
            id: 'awb_number',
            displayName: 'AWB ID'
        },
        {
            id: 'shipment_status',
            displayName: 'Shipment Status'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        },
    ];
    const exportOrder = cmsorder;
    const handleFilter = async (e) => {
        e.preventDefault();
        if (filtervalue) {
            addFormData('mis-filter-macamo-order-data', filtervalue).then((res) => {
                if (res.data.status === 1) {
                    setCmsorder(res.data.filterdata);
                }
            })
        }
        else {
            getAllCmsOrders()
        }
    };
    const resetOrder = () => {
        getAllCmsOrders()
    }
    const handleInputs = e => {
        setFilterValue({
            ...filtervalue,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All CMS Orders</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xl-10">
                                        <form action="POST" onSubmit={handleFilter}>
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Search By Name</label>
                                                    <input name="cname" id="cname" className="form-control" type="text" placeholder="Search by Name" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="onumber">Search by Order Number</label>
                                                    <input name="onumber" id="onumber" className="form-control" type="text" placeholder="Search by Order Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cmobile">Search by Mobile Number</label>
                                                    <input name="cmobile" id="cmobile" className="form-control" type="text" placeholder="Search by Mobile Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-2">
                                                    <label htmlFor="odate">Search by Order Date</label>
                                                    <input name="odate" id="odate" className="form-control" type="date" placeholder="Search by Order Date" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-1">
                                                    <button type="submit" className="btn btn-success btn-md btnfilter">Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-12 col-lg-12 col-xl-2">
                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xl-6">
                                                <button onClick={resetOrder} className="btn btn-warning btn-md btnfilter">Reset</button>
                                            </div>
                                            <div className="col-12 col-lg-12 col-xl-6">
                                                <CsvDownloader filename="All Orders"
                                                    extension=".csv"
                                                    datas={exportOrder}
                                                    columns={columns}
                                                >
                                                    <CiExport className="filtersvg" />
                                                    <button className="btn btn-info btn-md btnfilter"> Export</button>
                                                </CsvDownloader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Shipping ID</th>
                                        <th>Shipment Status</th>
                                        <th>AWB Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                        </>

                                        :
                                        records?.map((records, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td><span className="badge badge-success">{records?.order_number}</span></td>
                                                <td>{records?.cust_name}</td>
                                                <td>{records?.cust_phone}</td>
                                                <td>{records?.subtotal_price}</td>
                                                <td>{records?.created_date}</td>
                                                <td>{records?.created_time}</td>
                                                <td>{records?.state}</td>
                                                <td>{records?.city}</td>
                                                <td>{records?.pincode}</td>
                                                <td><span className="badge badge-warning">{records?.vendor_name}</span></td>
                                                <td><span className="badge badge-primary">{records?.shipingid}</span></td>
                                                <td><span className="badge badge-info">{records?.shipment_status}</span></td>
                                                <td><span className="badge badge-secondary">{records?.awb_number}</span></td>
                                                {(() => {
                                                    if (records?.awb_number != "") {
                                                        return (
                                                            <td><Link target="_blank" to={`https://shiprocket.co/tracking/${records?.awb_number}`} className="badge badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <td></td>
                                                        )
                                                    }
                                                })()}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Shipping ID</th>
                                        <th>Shipment Status</th>
                                        <th>AWB Number</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default MacamoOrder